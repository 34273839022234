import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationService } from '@ev-portals/cp/frontend/shared/util';
import { Environment } from '@ev-portals/cp/frontend/shared/util';
import { filter, first, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FederationService {
  #route = inject(ActivatedRoute);
  #router = inject(Router);
  #navigationService = inject(NavigationService);
  #environment = inject(Environment);

  logoutUrl$ = new Subject<string>();
  logoutProcessRunning = false;
  logoutSuccess$ = new Subject<void>();

  #loginPath = this.#environment.loginPath;
  #logoutPath = this.#environment.logoutPath;
  #acrValues = '3IAMSC/Login/External';

  #queryParams = {
    redirect_uri: `${this.#environment.baseUrl}/login`,
    client_id: this.#environment.authClientId,
    response_type: `code`,
    resourceServer: `Unencrypted`,
    scope: `DigitalCommercePlatform TechHeroScope`,
    acr_values: this.#acrValues,
  };

  constructor() {
    this.#handleSingleSignOn();
  }

  // A method to handle signle-sign-on
  #handleSingleSignOn(): void {
    this.#route.queryParamMap
      .pipe(
        filter(paramMap => (paramMap.get('sso') ? true : false)),
        first(),
      )
      .subscribe(paramMap => {
        // save current url in localStorage to redirect to it after login
        this.#navigationService.storeOriginalDestination(
          // Remove the sso query param, so we don't get into an endless loop
          this.#navigationService.removeQueryParam(this.#router.routerState.snapshot.url, 'sso'),
        );
        this.redirectToLogin();
      });
  }

  redirectToLogin(): void {
    window.location.href = this.#getLoginPath();
  }

  initLogout(): void {
    this.logoutProcessRunning = true;
    this.logoutUrl$.next(this.#logoutPath);
    this.logoutSuccess$.next();
  }

  finishLogout(): void {
    this.removeUserData();
    // stop to run logout process again, checked on iframe src change
    this.logoutProcessRunning = false;
    // force iframe reload
    // workaround for logout screen of federation service
    this.logoutUrl$.next('');
    setTimeout(() => {
      this.logoutUrl$.next(this.#logoutPath);
      // most of the pages requires login, so redirect to home
      this.#navigationService.navigateToHome();
    }, 0);
  }

  removeUserData(): void {
    sessionStorage.clear();
  }

  #getLoginPath(): string {
    return `${this.#loginPath}?${this.#getQueryString()}`;
  }

  #getQueryString(): string {
    return Object.entries(this.#queryParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join(`&`);
  }
}
